import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Fragment } from "react/jsx-runtime";
import { Item } from "../Reserva/item";
import { Reservas } from "../../utils/interface";
import { Loading } from "../../Componentes/Loading";
import { useReservas } from "../../hooks/useReservas";
import { useState } from "react";

export function Admin() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reserva, setReserva] = useState<undefined | Reservas>(undefined);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<{}>({});
  const { data, isLoading } = useReservas({ page, filter });

  const openModal = (reservaSelecionada: Reservas) => {
    setReserva(reservaSelecionada);
    onOpen();
  };
  return (
    <Fragment>
      <Box
        display={{ mc: "none", xl: "flex" }}
        flexDir={"column"}
        m={"auto"}
        bg={"white"}
        w={{ mc: "100%", xl: "70%" }}
        marginTop={"1%"}
        borderRadius={3}
        boxShadow={"rgba(0, 0, 0, 0.1) 0px 1px 2px 0px"}
        justifyContent={"center"}
      >
        <TableContainer
          sx={{
            borderRadius: "5px",
            border: "1px solid #cccccc",
          }}
        >
          <Table colorScheme="gray" bg={"white"}>
            <Thead>
              <Tr h={30} bg={"white"}>
                <Th textAlign={"center"}>Data:</Th>
                <Th textAlign={"center"}>Período:</Th>
                <Th textAlign={"center"}>Situação:</Th>
                <Th textAlign={"center"}>Reserva:</Th>
              </Tr>
            </Thead>
            {isLoading ? (
              <Loading />
            ) : (
              <Tbody>
                {data?.reserva.map((reserva: Reservas) => (
                  <Item
                    key={reserva.id}
                    reserva={reserva}
                    handleOpenDetalis={openModal}
                  />
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  );
}
