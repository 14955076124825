import { Fragment } from "react/jsx-runtime";
import { Reservas } from "../../utils/interface";
import {
  Box,
  Td,
  Text,
  Tr,
  VStack,
  Tooltip,
  IconButton,
  Badge,
} from "@chakra-ui/react";
import { LuCalendarPlus } from "react-icons/lu";
interface Props {
  reserva: Reservas;
  handleOpenDetalis: (reserva: Reservas) => void;
}

export function Item({ reserva, handleOpenDetalis }: Props) {
  return (
    <Fragment>
      <Tr
        textTransform={"capitalize"}
        h={65}
        key={reserva.id}
        _hover={{ backgroundColor: "#F4F4F4" }}
      >
        <Td>
          <Box display={"flex"} flexDir={"row"}>
            <Text fontWeight={500} m={"auto"}>
              {reserva.data}
            </Text>
          </Box>
        </Td>
        <Td>
          <Box display={"flex"} flexDir={"row"}>
            <Text fontWeight={500} m={"auto"}>
              {reserva.periodo}
            </Text>
          </Box>
        </Td>
        <Td>
          <Box display={"flex"} flexDir={"row"} ml={"10%"}>
            <Badge
              colorScheme={reserva.status === "Reservado" ? "red" : "green"}
              ml={"30%"}
              p={1}
            >
              {reserva.status}
            </Badge>
          </Box>
        </Td>
        <Td>
            {reserva.status === 'Disponivel' ? 
            <VStack>
            <Box display={"flex"} flexDirection={"row"} >
              <Tooltip
                label={"Reservar!"}
                placement="top"
                bg={"green.500"}
                borderRadius={"base"}
                hasArrow
              >
                <Box cursor={"pointer"} mt={-2}>
                  <IconButton
                    onClick={() => handleOpenDetalis(reserva)}
                    bg={"transparent"}
                    _hover={{ backgroundColor: "transparent" }}
                    aria-label="inactive"
                    icon={<LuCalendarPlus size={24} />}
                  />
                </Box>
              </Tooltip>
            </Box>
          </VStack>
          :
          ''
        }
        </Td>
      </Tr>
    </Fragment>
  );
}
