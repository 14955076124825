import { Box, Button, Image, Text } from "@chakra-ui/react";
import { Fragment } from "react/jsx-runtime";
import Banner1 from "../../images/clinicas-financeiras.jpg";
import Banner2 from "../../images/conta-jovem.jpg";
import Banner3 from "../../images/conta-pf.jpg";
import Banner from "../../images/banner-band-mulher.png";
import BannerHero from "../../images/hero-new.webp";

export function Home() {
  const handleClick = () => {
    window.open(`https://forms.office.com/r/5Np0CtriQe`);
  };

  const handleChandleClickCooplick = () => {
    window.open(
      "https://www.sicoob.com.br/web/sicoob/associacaodigital",
      "_blank"
    );
  };
  return (
    <Fragment>
      <Box mt={-5} bg={"red"}>
        <Image
          src={Banner}
          alt={"Banner Homer"}
          // height={{ base: "18vh", lg: "auto" }}
          width={"100%"}
          objectFit="cover"
        />
      </Box>
      {/* <Box
        display={"flex"}
        flexDir={{ mc: "column", xl: "row" }}
        backgroundImage={ImgFundo}
        bg={"red"}
      >
        <Box minW={"50%"} display={"flex"} flexDir={"column"}>
          <Text
            fontWeight={"bold"}
            fontSize={{ mc: "30px", xl: "60px" }}
            w={{ mc: "100%", xl: "60%" }}
            ml={{ mc: "%", xl: "30%" }}
            mt={{ mc: 10, xl: 20 }}
            color={"#00AE9D"}
            lineHeight={1.1}
            textAlign={{ mc: "center", xl: "left" }}
          >
            Consultoria Corporativa Personalizada
          </Text>
          <Text
            ml={{ mc: "0%", xl: "30%" }}
            fontSize={"xl"}
            mt={5}
            w={{ mc: "100%", xl: "60%" }}
            textAlign={{ mc: "center", xl: "left" }}
            lineHeight={1.4}
            color={"#6e6e6e"}
          >
            Transformando Desafios em Oportunidades com Estratégias
            Personalizadas
          </Text>
          <Button
            _hover={{ background: "#00AE9D", boxShadow: "none" }}
            marginTop={{ mc: "15%", xl: "10%" }}
            ml={"auto"}
            mr={"auto"}
            w={{ mc: "80%", xl: "40%" }}
            color={"#49479D"}
            border={"4px solid #003641"}
            bg={"white"}
            boxShadow={"rgba(125, 182, 28, 0.9) 5px 5px"}
            onClick={handleChandleClickCooplick}
          >
            Seja um Cooperado Aracoop
          </Button>
        </Box>
        <Box minW={"50%"} display={{ mc: "none", xl: "block" }}>
          <Image src={ImgHome} />
        </Box>
      </Box> */}
      <Box
        display={"flex"}
        bg={"#f0f0f0"}
        borderRadius={"8"}
        padding={{ mc: 5, xl: 30 }}
        mt={10}
      >
        <Image
          src={BannerHero}
          borderRadius={8}
          w={{ mc: "100%", xl: "45%" }}
          display={{ mc: "none", xl: "block" }}
        />
        <Box p={5}>
          <Text
            w={{ mc: "100%", xl: "70%" }}
            fontWeight={"bold"}
            fontSize={{ mc: "25px", xl: "40px" }}
            ml={{ mc: "1%", xl: "4%" }}
            textAlign={{ mc: "center", xl: "left" }}
            mt={"8%"}
            color={"#00AE9D"}
            lineHeight={1.2}
          >
            Band Mulher 2025
          </Text>
          <Text
            fontWeight={"400"}
            ml={"4%"}
            textAlign={{ mc: "center", xl: "justify" }}
            letterSpacing={2}
            mt={10}
            w={{ mc: "100%", xl: "60%" }}
            lineHeight={2}
            color={"#003641"}
            fontSize={{ mc: "xl", xl: "md" }}
          >
            O Sicoob Aracoop, em parceria com o Band Mulher, está selecionando
            empreendedoras cooperadas para compartilhar suas trajetórias
            inspiradoras.
            <br />
            <br /> Se você é cooperada e deseja contar sua história de desafios
            e conquistas, inscreva-se agora e mostre o seu poder empreendedor!
            <br />
            <br />
            Empreendedoras de Araguari, Araxá, Monte Carmelo, Uberaba,
            Uberlândia e região do Triângulo Mineiro, essa oportunidade é para
            você!
          </Text>
          <Button
            _hover={{ background: "white", boxShadow: "none" }}
            marginTop={{ mc: "10%", xl: "10%" }}
            marginLeft={{ mc: "10%", xl: "50%" }}
            color={"#49479D"}
            border={"4px solid #003641"}
            bg={"#00AE9D"}
            boxShadow={"rgba(125, 182, 28, 0.9) 5px 5px"}
            onClick={handleClick}
          >
            Inscreva-se agora!
          </Button>
        </Box>
      </Box>
      {/* <Box
        display={"flex"}
        bg={"#f0f0f0"}
        borderRadius={"8"}
        padding={{ mc: 5, xl: 30 }}
      >
        <Box p={5}>
          <Text
            w={{ mc: "100%", xl: "70%" }}
            fontWeight={"bold"}
            fontSize={{ mc: "25px", xl: "40px" }}
            ml={{ mc: "1%", xl: "4%" }}
            mt={5}
            color={"#00AE9D"}
            lineHeight={1.2}
          >
            Check up Finanças 2024
          </Text>
          <Text
            fontWeight={"400"}
            ml={"4%"}
            textAlign={"left"}
            fontSize={"lg"}
            mt={10}
            w={{ mc: "100%", xl: "60%" }}
            lineHeight={1.4}
            color={"#003641"}
          >
            Em parceria com o Sebrae Araguari o Sicoob Aracoop disponibiliza uma
            consultoria de 4h na sua empresa com especialista em finanças do
            Sebrae. A consultoria possibilita verificar os gargalos financeiros
            de todas as etapas de uma gestão financeira e crédito eficaz.
            Limitado a 01 (uma) consultoria por CNPJ. Agendamentos disponíveis
            para os seguintes períodos: Manhã (08h às 12h), tarde (14h às 18h).
          </Text>
          <Box ml={"4%"} mt={"6%"}>
            <Text
              w={{ mc: "100%", xl: "70%" }}
              fontWeight={"bold"}
              fontSize={{ mc: "25px", xl: "30px" }}
              color={"#49479D"}
            >
              Temáticas Abordadas
            </Text>
            <Box
              mt={3}
              display={"flex"}
              flexDir={"column"}
              p={{ mc: 0, xl: 2 }}
            >
              <Box
                display={"flex"}
                flexDir={{ mc: "column", xl: "row" }}
                gap={{ mc: "5", xl: "10" }}
                justifyContent={"space-between"}
                w={{ mc: "100%", xl: "80%" }}
              >
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Controles Financeiros e Capital de Giro{" "}
                </Text>
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Margem de Contribuição e Redução de custos
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexDir={{ mc: "column", xl: "row" }}
                gap={{ mc: "5", xl: "10" }}
                justifyContent={"space-between"}
                w={{ mc: "100%", xl: "80%" }}
              >
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Ponto de Equilíbrio
                </Text>
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Avaliação de Resultados
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexDir={{ mc: "column", xl: "row" }}
                gap={{ mc: "5", xl: "10" }}
                justifyContent={"space-between"}
                w={{ mc: "100%", xl: "80%" }}
              >
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Ciclo Financeiro e Giro de Caixa
                </Text>
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Análise de Indicadores Financeiros
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexDir={{ mc: "column", xl: "row" }}
                gap={{ mc: "5", xl: "10" }}
                justifyContent={"space-between"}
                w={{ mc: "100%", xl: "80%" }}
              >
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Planejamento Financeiro e Metas
                </Text>
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Acesso a Crédito e Serviços Financeiros
                </Text>
              </Box>
              <Box
                display={"flex"}
                flexDir={{ mc: "column", xl: "row" }}
                gap={{ mc: "5", xl: "10" }}
                justifyContent={"space-between"}
                w={{ mc: "100%", xl: "80%" }}
              >
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Renegociação de dívidas
                </Text>
                <Text mt={"1%"} color={"#7DB61C"} fontWeight={"bold"}>
                  Plano de ação, devolutiva e soluções Sebrae
                </Text>
              </Box>
            </Box>
          </Box>
          <Button
            _hover={{ background: "white", boxShadow: "none" }}
            marginTop={{ mc: "10%", xl: "10%" }}
            marginLeft={{ mc: "10%", xl: "60%" }}
            color={"#49479D"}
            border={"4px solid #003641"}
            bg={"#00AE9D"}
            boxShadow={"rgba(125, 182, 28, 0.9) 5px 5px"}
            onClick={handleClick}
          >
            Agenda seu Horário
          </Button>
        </Box>
        <Image
          src={ImgFundoDois}
          borderRadius={8}
          height={'57vh'}
          display={{ mc: "none", xl: "block" }}
        />
      </Box> */}
      <Box p={{ mc: 5, xl: 50 }} mt={{ mc: 10, xl: 0 }} bg={"white"} mb={"-5%"}>
        <Text
          fontWeight={"bold"}
          fontSize={{ mc: "30px", xl: "40px" }}
          ml={{ mc: "0%", xl: "11%" }}
          color={"#00AE9D"}
        >
          Seja um Cooperado Sicoob Aracoop
        </Text>
        <Text
          ml={{ mc: "0%", xl: "11%" }}
          w={{ mc: "100%", xl: "80%" }}
          fontWeight={"400"}
          color={"#003641"}
          mt={5}
          fontSize={"lg"}
        >
          O Sicoob é um Sistema de Cooperativas Financeiras que conta com mais
          de 4,6 mil pontos de atendimento, canais digitais diversos e um
          portfólio completo de produtos e serviços para você, sua empresa e o
          agronegócio:
        </Text>

        <Box
          display={"flex"}
          flexDir={{ mc: "column", xl: "row" }}
          justifyContent={"center"}
        >
          <Box
            borderRadius={7}
            maxW={{ mc: "100%", xl: "25%" }}
            mt={10}
            ml={"4%"}
            border={"1px solid #ccc"}
            p={2}
          >
            <Image borderRadius={"4%"} src={Banner3} />
            <Box>
              <Text
                textAlign={"center"}
                mt={7}
                fontWeight={"bold"}
                fontSize={"2xl"}
              >
                Produtos com Taxas Justas
              </Text>
              <Text
                textAlign={"center"}
                mt={2}
                fontSize={"lg"}
                fontWeight={"400"}
                color={"#00AE9D"}
              >
                Oferecemos produtos financeiros com taxas justas e
                transparentes, garantindo condições vantajosas e acessíveis para
                atender às suas necessidades.
              </Text>
              <Button
                display={"flex"}
                bg={"transparent"}
                border={"1px solid #49479D"}
                width={"80%"}
                ml={"10%"}
                mt={"14%"}
                color={"#49479D"}
                _hover={{
                  background: "#49479D",
                  color: "white",
                  transition: "0.7s",
                }}
                onClick={handleChandleClickCooplick}
              >
                Saiba Mais
              </Button>
            </Box>
          </Box>

          <Box
            borderRadius={7}
            maxW={{ mc: "100%", xl: "25%" }}
            mt={10}
            ml={"4%"}
            border={"1px solid #ccc"}
            p={2}
          >
            <Image borderRadius={"4%"} src={Banner2} />
            <Box>
              <Text
                textAlign={"center"}
                mt={7}
                fontWeight={"bold"}
                fontSize={"2xl"}
              >
                Atendimento Próximo e Acolhedor
              </Text>
              <Text
                textAlign={"center"}
                mt={2}
                fontSize={"lg"}
                fontWeight={"400"}
                color={"#00AE9D"}
              >
                Nosso atendimento é personalizado e próximo, com profissionais
                dedicados a ouvir suas necessidades e oferecer soluções que
                realmente fazem a diferença para você.
              </Text>
              <Button
                display={"flex"}
                bg={"transparent"}
                border={"1px solid #49479D"}
                width={"80%"}
                ml={"10%"}
                mt={"8%"}
                color={"#49479D"}
                _hover={{
                  background: "#49479D",
                  color: "white",
                  transition: "0.7s",
                }}
                onClick={handleChandleClickCooplick}
              >
                Saiba Mais
              </Button>
            </Box>
          </Box>

          <Box
            borderRadius={7}
            maxW={{ mc: "100%", xl: "25%" }}
            mt={10}
            ml={"4%"}
            border={"1px solid #ccc"}
            p={2}
          >
            <Image borderRadius={"4%"} src={Banner1} />
            <Box>
              <Text
                textAlign={"center"}
                mt={7}
                fontWeight={"bold"}
                fontSize={"2xl"}
              >
                Em vez de ser Cliente, ser Dono
              </Text>
              <Text
                textAlign={"center"}
                mt={2}
                fontSize={"lg"}
                fontWeight={"400"}
                color={"#00AE9D"}
              >
                Na Sicoob, você não é apenas um cliente; é um membro e
                coproprietário. Junte-se a nós para ter voz ativa nas decisões e
                compartilhar os resultados positivos.
              </Text>
              <Button
                display={"flex"}
                bg={"transparent"}
                border={"1px solid #49479D"}
                width={"80%"}
                ml={"10%"}
                mt={"8%"}
                color={"#49479D"}
                _hover={{
                  background: "#49479D",
                  color: "white",
                  transition: "0.7s",
                }}
                onClick={handleChandleClickCooplick}
              >
                Saiba Mais
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}
