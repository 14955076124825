import { useMutation } from "@tanstack/react-query";
import { useToast } from '@chakra-ui/react';
import { api } from "../lib/axios";
import { newFormDataAddReserva } from "../utils/schema";
import { queryClient } from "../lib/reactyQuery";

export const useMutationAdd = (id: number) => {
    const toast = useToast();

    return useMutation({
        mutationFn: async (data: newFormDataAddReserva) => {
            await api.put(`/reservas/${id}`, {
                razaoSocial: data.razaoSocial,
                cnpj: data.cnpj,
                nomeInscrito: data.nomeInscrito,
                telefone: data.telefone,
                email: data.email,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['reservas'] });
            toast({ title: 'Reservado!', status: 'success', duration: 5000, isClosable: true, position: 'top-right' });
        }
    });
}