import { useQuery } from "@tanstack/react-query";
import { api } from "../lib/axios";
import { FilterReserva, MetaPagination, Reservas } from "../utils/interface";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

interface ReservaProps {
  page: number;
  filter: FilterReserva | undefined;
}

interface getCategoriasResponse {
  reserva: Reservas[];
  meta: MetaPagination;
}

async function getReservasAll({
  page,
  filter,
}: ReservaProps): Promise<getCategoriasResponse> {
  const response = await api
    .get("/reservas", {
      params: {
        page,
        ...filter
      },
    })
    .then((response) => response.data);

  const reserva: Reservas[] = response.data.map((reserva: Reservas) => {
    return {
      ...reserva,
      data: format(new Date(reserva.data),'dd-MM-yyyy', {locale: ptBR}),
    };
  });

  const meta = {
    current_page: response.current_page,
    last_page: response.last_page,
    per_page: response.per_page,
    to: response.to,
    total: response.total
}

  return {
    reserva,
    meta
  };
}

export function useReservas({ page, filter}: ReservaProps) {
  return useQuery({
    queryKey: ["reservas", { page, filter }],
    refetchOnWindowFocus: false,
    queryFn: () => getReservasAll({ page, filter }),
  });
}
