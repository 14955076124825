import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Logo from "../../images/logo-h-cor-dark.svg";
import { Link, Outlet, useNavigate } from "react-router-dom";

export function DashBoard() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  const handleClickCadastro = () => {
    window.open(`https://forms.office.com/r/5Np0CtriQe`);
  };

  return (
    <Flex w={"100%"} flexDir={"column"}>
      <Box
        w={"100%"}
        bg={"#003641"}
        h={"8vh"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Image
          cursor={"pointer"}
          onClick={handleClick}
          src={Logo}
          boxSize={150}
          h={"auto"}
          ml={"3%"}
        />
        <Box
          display={{ mc: "none", xl: "flex" }}
          alignItems={"center"}
          gap={10}
          mr={10}
        >
          <Text>
            <li
              onClick={handleClickCadastro}
              style={{
                listStyle: "none",
                color: "white",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Inscreva-se agora!
            </li>
          </Text>
          <Link
            to={"https://www.sicoob.com.br/web/sicoob/associacaodigital"}
            target="_blank"
          >
            <li
              style={{
                listStyle: "none",
                color: "white",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Seja um Cooperado
            </li>
          </Link>
          <li
            style={{
              listStyle: "none",
              color: "white",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Fale Conosco
          </li>
        </Box>
      </Box>

      <Box
        height={"83vh"}
        maxW={"100%"}
        justifyContent={"center"}
        m={"0 auto"}
        width={"100%"}
        mt={"4"}
      >
        <Outlet />
      </Box>
    </Flex>
  );
}
