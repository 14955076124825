import * as zod from "zod";

export const validationSchemaReservaCreate = zod.object({
    razaoSocial: zod.string().min(2),
    cnpj: zod.string().min(8),
    nomeInscrito: zod.string().min(2),
    telefone: zod.string().min(5),
    email: zod.string().min(4)
})

export type newFormDataAddReserva = zod.infer<typeof validationSchemaReservaCreate>;